<template>
    <v-container
        class="d-flex justify-space-between flex-column flex-shrink-1 px-10"
    >
        <v-dialog
            max-width="1280"
            v-model="txtInstructDialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-center">
                    <p>
                        If you have already prepared and plugged in your device,
                        press the connect button below.
                    </p>
                </div>
                <v-expansion-panels>
                    <v-expansion-panel
                        elevation="12"
                        class="d-flex justify-center darken-2"
                    >
                        <v-expansion-panel-header>
                            Expected device screen
                        </v-expansion-panel-header>
                        <v-lazy>
                            <v-expansion-panel-content>
                                <v-carousel :show-arrows="false">
                                    <v-carousel-item
                                        contain
                                        src="/Copperheadpixel-fastbootlocked.webp"
                                    ></v-carousel-item>
                                </v-carousel>
                            </v-expansion-panel-content>
                        </v-lazy>
                    </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <v-btn
                    title="Instructions"
                    class="d-flex flex-column flex-shrink-1 rounded-pill"
                    fixed
                    bottom
                    elevation="12"
                    icon
                    large
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon large>mdi-help</v-icon>
                </v-btn>

                <v-btn
                    title="Connect"
                    :color="$root.$data.product === null ? 'primary' : null"
                    @click="connect"
                    :disabled="connecting"
                >
                    Connect
                </v-btn>

                <div class="mb-4">
                    <connect-banner
                        :device="device"
                        :connecting="connecting"
                        :error="error"
                    />
                </div>

                <div class="d-flex justify-space-between flex-row-reverse">
                    <v-btn
                        title="Next Button"
                        color="primary"
                        @click="$bubble('nextStep')"
                        :disabled="$root.$data.product === null"
                    >
                        Next
                        <v-icon dark right>mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn title="Back Button" text @click="$bubble('prevStep')"
                        >Back</v-btn
                    >
                </div>
            </template>

            <v-card class="flex-shrink-1 pa-md-4 mx-lg-auto">
                <v-btn
                    title="Close"
                    class="float-right"
                    icon
                    dark
                    @click="txtInstructDialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-card-title
                    ><h6 class="text-h6 pb-4">
                        Preparing a device for install...
                    </h6>
                </v-card-title>
                <div class="mb-10 mt-n4">
                    <p class="text-body-1">
                        1. Connect the device to the internet over WiFi.
                    </p>
                    <p>
                        2. Open Settings -> About Phone, find "Build Number" and
                        tap "Build Number" seven times to enable
                        <strong>Developer Mode</strong>.
                    </p>
                    <p></p>

                    <p>
                        3. Open Settings -> System -> Advanced -> Developer
                        Options and find <strong>OEM Unlocking</strong>. Toggle
                        it on and confirm you want to enable it if prompted. A
                        connection to the internet is required to change this
                        setting on new devices.
                    </p>
                    <p>
                        4. Put your device into fastboot mode by first powering
                        the device off completely. Press the Power Key until the
                        Power Menu appears, and choose “Shut Down”. Please wait
                        for the screen to blank or show the battery charge
                        animation.
                    </p>
                    <p>
                        5. With the device off, hold the Power Key and Volume
                        Down button until you see the
                        <span class="red--text text--darken-3">red</span> text
                        of the fastboot warning screen on newer Pixel devices,
                        or
                        <span class="green--text text--darken-3">green</span>
                        Android robot on older pixel devices.
                    </p>
                    <p>
                        Once your device is in fastboot mode, plug it into the
                        device you’re installing from (Usually the machine
                        running this installer). Make sure you use a
                        <strong>high-quality</strong> USB cable, as many cables
                        will cause issues. Avoid USB hubs if possible.
                    </p>
                    <p>
                        Your USB cable needs to be able to copy files.
                        Charging-only cables won’t work.
                    </p>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ConnectBanner from "./ConnectBanner";
import { getDeviceName } from "../core/devices";

export default {
    name: "ConnectStep",

    components: {
        ConnectBanner,
    },

    props: ["user", "device", "blobStore", "active"],

    data: () => ({
        txtInstructDialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        connecting: false,
        error: null,
        firstConnect: true,
    }),

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_connect");
            }
        },
    },

    methods: {
        getDeviceName,

        async errorRetry() {
            await this.connect();
        },

        async connect() {
            this.connecting = true;

            try {
                await this.device.connect();
                this.$root.$data.product = await this.device.getVariable(
                    "product"
                );
                this.error = null;

                if (this.firstConnect) {
                    this.firstConnect = false;
                    this.$bubble("nextStep");
                }

                this.saEvent(`device_connect__${this.$root.$data.product}`);
            } catch (e) {
                let [handled, message] = this.bubbleError(e);
                this.error = message;
                if (!handled) {
                    throw e;
                }
            } finally {
                this.connecting = false;
            }
        },
    },
};
</script>
