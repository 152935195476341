<template>
    <v-container class="d-flex flex-column flex-shrink-1 px-10">
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-alert
                    class="mb-6 mt-n2"
                    type="success"
                    text
                    title="CopperheadOS 13"
                    >
                    <span
                    v-bind="attrs"
                    v-on="on"
                    >CopperheadOS 13 is now available!</span>
                </v-alert>
            </template>
            <v-list-item
                disabled
                dense
                inactive
                two-line
                v-for="device in supportedTooltip"
                :key="device.model"
            >
                <v-list-item-content>
                    <v-list-item-title>{{ device.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                        device.model
                    }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-tooltip>
        <div class="mb-10 mt-n4" v-if="usbSupported">
            <h6 class="text-h6 pb-2">Prepare your device</h6>
            <div class="text-body-1" v-if="cosVersion !== null">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <p>
                            This is an easy way to install
                            {{ $root.$data.OS_NAME }}
                            <span
                                class="green--text text--darken-3"
                                v-bind="attrs"
                                v-on="on"
                                >on supported devices.</span
                            >
                        </p>
                    </template>
                    <v-list-item
                        disabled
                        dense
                        inactive
                        two-line
                        v-for="device in supportedTooltip"
                        :key="device.model"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{
                                device.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                                device.model
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-tooltip>
            </div>
            <v-dialog
                max-width="80vw"
                v-model="vidInstructDialog"
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex flex-wrap px-10 mb-2 mt-n4">
                        <p>1. Connect the device to the internet over WiFi.</p>
                        <p>
                            2. Open Settings -> About Phone, find "Build Number"
                            and tap "Build Number" seven times to enable
                            <strong>Developer Mode</strong>.
                        </p>
                        <v-btn
                            title="OEM Unlock Demonstration"
                            class="pa-1 float-right rounded-pill"
                            elevation="12"
                            icon
                            small
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon large>mdi-filmstrip</v-icon>
                        </v-btn>
                        <p>
                            3. Open Settings -> System -> Advanced -> Developer
                            Options and find
                            <strong>OEM Unlocking</strong>. Toggle it on and
                            confirm you want to enable it if prompted. A
                            connection to the internet is required to change
                            this setting on new devices.
                        </p>
                        <p>
                            4. Put your device into fastboot mode by first
                            powering the device off completely. Press the Power
                            Key until the Power Menu appears, and choose “Shut
                            Down”. Please wait for the screen to blank or show
                            the battery charge animation.
                        </p>
                        <p>
                            5. With the device off, hold the Power Key and
                            Volume Down button until you see the mostly
                            <span class="red--text text--darken-3">red</span>
                            text of the fastboot warning screen on Pixel
                            devices.
                        </p>
                        <p>
                            Once your device is in fastboot mode, plug it into
                            the device you’re installing from (Usually the
                            machine running this installer). Make sure you use a
                            <strong>high-quality</strong> USB cable, as many
                            cables will cause issues. Avoid USB hubs if
                            possible.
                        </p>
                        <p>
                            Your USB cable needs to be able to copy files.
                            Charging-only cables won’t work.
                        </p>
                        <div class="flex-shrink-1 pa-md-4 mx-lg-auto">
                            <v-btn
                                dark
                                :disabled="disableClear"
                                @click="handle_clear()"
                            >Clear Stored Files</v-btn>
                        </div>
                    </div>
                </template>
                <v-card class="flex-shrink-1 pa-md-4 mx-lg-auto">
                    <v-toolbar dense dark color="primary">
                        <v-btn icon dark @click="vidInstructDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>OEM Unlocking</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="vidInstructDialog = false">
                                Return
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-skeleton-loader>
                        <v-lazy>
                            <video playsinline>
                                <source
                                    size="640"
                                    src="/oemunlock-minified.mp4"
                                />
                            </video>
                        </v-lazy>
                    </v-skeleton-loader>
                </v-card>
            </v-dialog>
        </div>

        <div class="mb-4 mt-n4" v-else>
            <h6 class="text-h6 pb-4 red--text text--darken-4">
                Your browser isn’t supported
            </h6>

            <div class="text-body-1">
                <p>
                    Unfortunately, you can’t use this web installer for
                    {{ $root.$data.OS_NAME }} because your browser doesn’t
                    support WebUSB. Only Google Chrome and other browsers based
                    on Chromium, such Brave and Microsoft Edge, are supported.
                </p>
            </div>

            <div class="text-body-1 mt-4">
                <p>
                    If you think this is a mistake, update your browser to the
                    latest version and try again.
                </p>
            </div>
        </div>

        <div class="d-flex justify- flex-row-reverse">
            <v-btn
                title="Next Button"
                color="primary"
                @click="$bubble('nextStep')"
                :disabled="!usbSupported"
            >
                Start
                <v-icon dark right>mdi-arrow-right</v-icon>
            </v-btn>
        </div>
    </v-container>
</template>

<script>
export default {
    props: ["user", "device", "blobStore", "active"],

    data: () => ({
        usbSupported: "usb" in navigator,
        vidInstructDialog: false,
        cosVersion: null,
        disableClear: false,
        supportedTooltip: [
            {
                name: "Pixel 4",
                model: "flame",
            },
            {
                name: "Pixel 4 XL",
                model: "coral",
            },
            {
                name: "Pixel 4a",
                model: "sunfish",
            },
            {
                name: "Pixel 4a 5G",
                model: "bramble",
            },
            {
                name: "Pixel 5",
                model: "redfin",
            },
            {
                name: "Pixel 5a",
                model: "barbet",
            },
            {
                name: "Pixel 6",
                model: "oriole",
            },
            {
                name: "Pixel 6a",
                model: "bluejay",
            },
            {
                name: "Pixel 7",
                model: "panther",
            },
        ],
    }),

    async created() {
        if (this.cosVersion === null) {
            let versionResp = await fetch("/releases/version.json");
            this.cosVersion = await versionResp.json();
        }
        this.$root.$data.cosRelease = this.cosVersion;
    },

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_prepare");
            }
        },
    },

    methods: {
        async handle_clear() {
            await this.blobStore.init();
            await this.blobStore.clear();
            alert("IndexedDB file store cleared.");

            this.disableClear = true;
        },
    },
};
</script>
