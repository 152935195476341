<template>
    <v-container
        class="d-flex justify-space-between flex-column flex-shrink-1 px-10"
    >
        <div class="mt-n4">
            <h6 class="text-h6 pb-4">Lock your bootloader</h6>

            <div class="text-body-1">
                <p>
                    In order to protect the device from physical tampering, you
                    need to lock the device’s bootloader.
                </p>
                <p>
                    Press the green Lock button below, then please use the
                    Volume Up or Down Keys on the device to select "Lock the
                    bootloader" and press the Power Key to confirm.
                </p>
            </div>
            <v-expansion-panels>
                <v-expansion-panel
                    elevation="12"
                    class="d-flex justify-center darken-2"
                >
                    <v-expansion-panel-header>
                        Expected device screens
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-lazy>
                            <v-carousel :show-arrows="false">
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-donotlock.webp"
                                ></v-carousel-item>
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-lockbootloader.webp"
                                ></v-carousel-item>
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-fastbootlocked.webp"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-lazy>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <br />
            <v-btn
                title="Lock"
                color="primary"
                @click="lock"
                :disabled="locking || locked"
                >Lock</v-btn
            >
        </div>

        <div class="mb-4">
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-if="confirmLockedBool"
            >
                <v-icon slot="icon" color="green darken-3">mdi-check</v-icon>
                <span class="text-body-1 green--text text--darken-3"
                    >Bootloader locked!</span
                >
            </v-banner>
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-else-if="locked"
            >
                <v-progress-circular
                    slot="icon"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <span class="text-body-1"
                    >Locking bootloader… Confirm using the volume and power
                    buttons, then below.</span
                >
            </v-banner>
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-else-if="error"
            >
                <v-icon slot="icon" color="red darken-3">mdi-close</v-icon>
                <span class="text-body-1 red--text text--darken-3">{{
                    error
                }}</span>
            </v-banner>
        </div>

        <div class="d-flex justify-space-between flex-row-reverse">
            <v-btn
                title="Next Button"
                color="primary"
                @click="$bubble('nextStep')"
                :disabled="this.confirmLockedBool === false"
                >Next <v-icon dark right>mdi-arrow-right</v-icon></v-btn
            >
            <v-checkbox
                label="Yes, I have locked the bootloader successfully."
                :value="0"
                outlined
                dark
                ripple
                :color="this.locked === true ? 'green darken-2' : null"
                :class="this.locked === true ? 'v-card--selected' : null"
                @click="confirmLocked(true)"
                :disabled="!locked"
            ></v-checkbox>
            <v-btn title="Back Button" text @click="$bubble('prevStep')"
                >Back</v-btn
            >
        </div>

        <v-dialog v-model="oemUnlockDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline">
                    Enable OEM unlocking
                </v-card-title>

                <v-card-text>
                    <p>
                        For security reasons, bootloader unlock isn’t allowed by
                        default. Enable OEM unlocking to allow it:
                    </p>

                    <ol class="ml-4 mb-4">
                        <li>Restart back to Android</li>
                        <li>
                            Go to Settings → “About phone” and scroll to the
                            bottom
                        </li>
                        <li>
                            Tap “Build number” repeatedly until developer
                            options is unlocked
                        </li>
                        <li>
                            Go to Settings → System → Advanced → “Developer
                            options”
                        </li>
                        <li>Turn on “OEM unlocking”</li>
                        <li>Restart back to the bootloader</li>
                    </ol>

                    <p>
                        Once you’ve enabled OEM unlocking, try unlocking the
                        bootloader again.
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        title="Retry"
                        color="primary"
                        text
                        @click="retryOemUnlock"
                    >
                        Retry
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.theme--light.v-sheet--outlined {
    border-width: 2px;
}

.theme--light.v-sheet--outlined.v-card--p-highlight {
    border: 2px solid #007cfa !important;
}
</style>

<script>
import { FastbootError } from "android-fastboot";

export default {
    name: "LockStep",

    props: ["user", "device", "blobStore", "active"],

    data: () => ({
        confirmLockedBool: false,
        locking: false,
        locked: undefined,
        initialLocked: undefined,
        firstLock: true,
        error: null,
        oemUnlockDialog: false,
    }),

    watch: {
        curStep: async function (newStep, oldStep) {
            if (newStep == this.stepNum) {
                this.saEvent("step_lock");

                try {
                    // Get lock state once and save it. Not all bootloaders
                    // update the locked value immediately after locking.
                    if (this.locked === undefined) {
                        this.locked =
                            (await this.device.getVariable("locked")) === "yes";
                        this.initialLocked = this.locked;
                    }

                    // Skip step only if unlock state was never changed
                    if (this.locked && this.initialLocked) {
                        if (newStep > oldStep) {
                            this.$bubble("nextStep");
                        } else {
                            this.$bubble("prevStep");
                        }
                    }

                    this.error = null;
                } catch (e) {
                    let [handled, message] = this.bubbleError(e);
                    this.error = message;
                    if (!handled) {
                        throw e;
                    }
                }
            }
        },
    },

    methods: {
        async retryOemUnlock() {
            this.oemUnlockDialog = false;
            await this.lock();
        },

        async errorRetry() {
            await this.lock();
        },

        async lock() {
            this.locking = true;

            try {
                if (!this.device.isConnected) {
                    await this.device.connect();
                }

                // Unlocking can't be done in fastbootd
                if ((await this.device.getVariable("is-userspace")) === "yes") {
                    await this.device.reboot("bootloader", true, () => {
                        this.$bubble("requestDeviceReconnect");
                    });
                }

                await this.device.runCommand("flashing lock");
            } catch (e) {
                this.locking = false;

                if (e instanceof FastbootError && e.status === "FAIL") {
                    if (e.message.includes("already")) {
                        /* Already locked = success */
                        return;
                    } else if (e.message.includes("canceled")) {
                        this.error = "Lock request was canceled";
                        return;
                    }
                }

                let [handled, message] = this.bubbleError(e);
                this.error = message;
                if (!handled) {
                    throw e;
                }
            }

            this.locked = true;
            this.error = null;

            this.locking = false;
            this.saEvent(`lock_bootloader__${this.$root.$data.product}`);
        },

        async confirmLocked() {
            this.confirmLockedBool = true;
        },
    },
};
</script>
