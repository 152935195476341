<template>
    <v-container
        class="d-flex justify-space-between flex-column flex-shrink-1 px-10"
    >
        <div class="mt-n4">
            <h6 class="text-h6 pb-4">Unlock your bootloader</h6>
            <div class="text-body-1">
                <p>
                    In order to install custom software, you need to unlock your
                    device’s bootloader. For a preview of what this will look
                    like on the device, click "Expected device screens" below.
                </p>
                <p>
                    When you are ready to begin, press the green Unlock button
                    below.
                </p>
                <p>
                    You will be prompted on the device, you will need to select
                    "Unlock the bootloader" using the Volume Up and Down Keys
                    and the Power Key to confirm the selection.
                </p>
            </div>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        If you have not already turned on OEM Unlocking
                        <v-icon color="primary">mdi-help</v-icon>
                    </v-expansion-panel-header>
                    <v-lazy>
                        <v-expansion-panel-content>
                            <p>
                                OEM Unlocking is a prerequisite for unlocking
                                the bootloader
                            </p>
                            <ol class="ml-4 mb-8">
                                <li>Restart back to Android</li>
                                <li>
                                    Go to Settings → “About phone” and scroll to
                                    the bottom
                                </li>
                                <li>
                                    Tap “Build number” repeatedly until
                                    developer options is unlocked
                                </li>
                                <li>
                                    Go to Settings → System → Advanced →
                                    “Developer options”
                                </li>
                                <li>Turn on “OEM unlocking”</li>
                                <li>Restart back to the bootloader</li>
                            </ol>
                        </v-expansion-panel-content>
                    </v-lazy>
                </v-expansion-panel>
                <v-expansion-panel
                    elevation="12"
                    class="d-flex justify-center darken-2"
                >
                    <v-expansion-panel-header>
                        Expected device screens
                    </v-expansion-panel-header>
                    <v-lazy>
                        <v-expansion-panel-content>
                            <v-carousel :show-arrows="false">
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-donotunlock.webp"
                                ></v-carousel-item>
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-unlockbootloader.webp"
                                ></v-carousel-item>
                                <v-carousel-item
                                    contain
                                    src="/Copperheadpixel-fastbootunlocked.webp"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-expansion-panel-content>
                    </v-lazy>
                </v-expansion-panel>
            </v-expansion-panels>
            <br />
            <v-btn
                title="Unlock"
                color="primary"
                @click="unlock"
                :disabled="unlocking || unlocked"
                >Unlock</v-btn
            >
        </div>
        <div class="mb-4">
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-if="confirmUnlockedBool"
            >
                <v-icon slot="icon" color="green darken-3">mdi-check</v-icon>
                <span class="text-body-1 green--text text--darken-3">
                    Bootloader Unlocked!
                </span>
            </v-banner>
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-else-if="unlocked"
            >
                <v-progress-circular
                    slot="icon"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <span class="text-body-1 green--text text--darken-3"
                    >Unlock command sent. Confirm on device, then below.</span
                >
            </v-banner>
            <v-banner
                single-line
                outlined
                rounded
                class="mt-4"
                v-else-if="error"
            >
                <v-icon slot="icon" color="red darken-3">mdi-close</v-icon>
                <span class="text-body-1 red--text text--darken-3">{{
                    error
                }}</span>
            </v-banner>
        </div>

        <div class="d-flex justify-space-between flex-row-reverse">
            <v-btn
                title="Next Button"
                color="primary"
                @click="$bubble('nextStep')"
                :disabled="this.confirmUnlockedBool === false"
                >Next <v-icon dark right>mdi-arrow-right</v-icon></v-btn
            >

            <v-checkbox
                label="Yes, I have unlocked the bootloader successfully."
                :value="0"
                outlined
                dark
                ripple
                :color="this.unlocked === true ? 'green darken-2' : null"
                :class="this.unlocked === true ? 'v-card--selected' : null"
                @click="confirmUnlocked(true)"
                :disabled="!unlocked"
            ></v-checkbox>

            <v-btn title="Back Button" text @click="$bubble('prevStep')"
                >Back</v-btn
            >
        </div>

        <v-dialog v-model="oemUnlockDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline">
                    Enable OEM unlocking
                </v-card-title>

                <v-card-text>
                    <p>
                        For security reasons, bootloader unlock isn’t allowed by
                        default. Enable OEM unlocking to allow it:
                    </p>

                    <ol class="ml-4 mb-4">
                        <li>Restart back to Android</li>
                        <li>
                            Go to Settings → “About phone” and scroll to the
                            bottom
                        </li>
                        <li>
                            Tap “Build number” repeatedly until developer
                            options is unlocked
                        </li>
                        <li>
                            Go to Settings → System → Advanced → “Developer
                            options”
                        </li>
                        <li>Turn on “OEM unlocking”</li>
                        <li>Restart back to the bootloader</li>
                    </ol>

                    <p>
                        Once you’ve enabled OEM unlocking, try unlocking the
                        bootloader again.
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        title="Retry"
                        color="primary"
                        text
                        @click="retryOemUnlock"
                    >
                        Retry
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { FastbootError } from "android-fastboot";

export default {
    name: "UnlockStep",

    props: ["user", "device", "blobStore", "curStep", "stepNum"],

    data: () => ({
        confirmUnlockedBool: false,
        unlocking: false,
        unlocked: undefined,
        initialUnlocked: undefined,
        firstUnlock: true,
        error: null,
        oemUnlockDialog: false,
    }),

    watch: {
        curStep: async function (newStep, oldStep) {
            if (newStep == this.stepNum) {
                this.saEvent("step_unlock");

                try {
                    // Get unlock state once and save it. Not all bootloaders
                    // update the unlocked value immediately after unlocking.
                    if (this.unlocked === undefined) {
                        this.unlocked =
                            (await this.device.getVariable("unlocked")) ===
                            "yes";
                        this.initialUnlocked = this.unlocked;
                    }

                    // Skip step only if unlock state was never changed
                    if (this.unlocked && this.initialUnlocked) {
                        if (newStep > oldStep) {
                            this.$bubble("nextStep");
                        } else {
                            this.$bubble("prevStep");
                        }
                    }

                    this.error = null;
                } catch (e) {
                    let [handled, message] = this.bubbleError(e);
                    this.error = message;
                    if (!handled) {
                        throw e;
                    }
                }
            }
        },
    },

    methods: {
        async retryOemUnlock() {
            this.oemUnlockDialog = false;
            await this.unlock();
        },

        async errorRetry() {
            await this.unlock();
        },

        async unlock() {
            this.unlocking = true;

            try {
                if (!this.device.isConnected) {
                    await this.device.connect();
                }

                // Unlocking can't be done in fastbootd
                if ((await this.device.getVariable("is-userspace")) === "yes") {
                    await this.device.reboot("bootloader", true, () => {
                        this.$bubble("requestDeviceReconnect");
                    });
                }

                await this.device.runCommand("flashing unlock");
            } catch (e) {
                this.unlocking = false;

                if (e instanceof FastbootError && e.status === "FAIL") {
                    if (e.message.includes("already")) {
                        /* Already unlocked = success */
                        return;
                    } else if (e.message.includes("canceled")) {
                        this.error = "Unlock request was canceled";
                        return;
                    } else if (e.message.includes("not allowed")) {
                        this.error = "OEM unlocking is not enabled";
                        this.oemUnlockDialog = true;
                        return;
                    }
                }

                let [handled, message] = this.bubbleError(e);
                this.error = message;
                if (!handled) {
                    throw e;
                }
            }

            this.unlocked = true;
            this.error = null;

            this.unlocking = false;
            this.saEvent(`unlock_bootloader__${this.$root.$data.product}`);
        },
        async confirmUnlocked() {
            this.confirmUnlockedBool = true;
        },
    },
};
</script>
