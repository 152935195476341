<template>
    <v-app id="inspire">
        <amplify-auth-container>
            <amplify-authenticator>
                <amplify-sign-in
                    header-text="Sign in to your Copperhead Partner Account"
                    hide-sign-up
                    slot="sign-in"
                >
                    <div
                        class="d-flex flex-row justify-center"
                        slot="header-subtitle"
                    >
                        <v-img
                            class="align-self-stretch"
                            max-width="200"
                            src="../public/copperheadlogo-min-white.svg"
                        ></v-img>
                    </div>
                    <div slot="federated-buttons"></div
                ></amplify-sign-in>
                <AppBar :user="user" />
                <v-main
                    class="grey darken-4"
                    v-if="authState === 'signedin' && user"
                >
                    <v-container fill-height>
                        <v-row align="center" justify="center">
                            <v-col cols="8" sm="2"></v-col>
                            <v-col
                                cols="16"
                                sm="8"
                                class="d-flex flex-row justify-center"
                            >
                                <v-sheet
                                    :min-height="
                                        $vuetify.breakpoint.mobile
                                            ? '100vh'
                                            : '75vh'
                                    "
                                    width="66vw"
                                    :rounded="
                                        $vuetify.breakpoint.mobile ? null : 'lg'
                                    "
                                    :elevation="
                                        $vuetify.breakpoint.mobile ? 0 : 4
                                    "
                                    class="d-flex flex-column"
                                >
                                    <Installer :user="user" />
                                </v-sheet>
                            </v-col>
                            <v-col cols="8" sm="2"></v-col>
                        </v-row>
                    </v-container>
                </v-main>
            </amplify-authenticator>
        </amplify-auth-container>
    </v-app>
</template>

<style>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    padding: 0 !important;
}
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;300;400;500;700;900&family=Fira+Sans:ital,wght@0,100;0,300;0,400;0,500;0,900;1,700&display=swap");
</style>

<script>
import Installer from "./components/Installer";
import AppBar from "./components/AppBar";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

export default {
    name: "App",
    created() {
        this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            this.user = authData;
        });

        this.sessionData = Auth.currentSession()
            .then((sdata) => sdata)
            .catch((err) => console.log(err));
    },
    components: {
        AppBar,
        Installer,
    },
    data: () => ({
        user: undefined,
        authState: undefined,
        unsubscribeAuth: undefined,
    }),
    beforeDestroy() {
        this.unsubscribeAuth();
    },
};
</script>
