<template>
    <v-container
        class="d-flex justify-space-between flex-column flex-shrink-1 px-10"
    >
        <div class="mt-n4">
            <h6 class="text-h6 pb-4">Choose an install type</h6>

            <div class="text-body-1">
                <p>
                    This installer will flash {{ $root.$data.OS_NAME }}
                    <strong
                        >Beware that Pixel 6 specifically should not be
                        downgraded from any Android 13 Stock or ROM.</strong
                    >
                </p>
            </div>
        </div>

        <div class="d-flex flex-wrap justify-space-around">
            <v-card
                light
                outlined
                max-width="32rem"
                class="ma-4 d-flex flex-column"
            >
                <v-card-title class="mt-n2">
                    <v-icon class="pr-2 py-2" color="rgba(0, 0, 0, 0.87)"
                        >mdi-cellphone-erase</v-icon
                    >
                    Clean install</v-card-title
                >
                <v-card-subtitle>
                    <p>
                        Switch from the stock OS or another ROM to
                        {{ $root.$data.OS_NAME }}.
                        <strong
                            class="font-weight-bold text-decoration-underline"
                        >
                            All data on your device will be lost.
                        </strong>
                    </p>
                </v-card-subtitle>
            </v-card>

            <v-checkbox
                label="Yes, wipe user data and install CopperheadOS."
                :value="0"
                outlined
                dark
                class="ma-4 d-flex flex-column"
                ripple
                :color="
                    $root.$data.installType === 'clean'
                        ? 'green darken-2'
                        : null
                "
                :class="
                    $root.$data.installType === 'clean'
                        ? 'v-card--selected'
                        : null
                "
                @click="setType('clean')"
            >
            </v-checkbox>
        </div>

        <div class="d-flex justify-space-between flex-row-reverse">
            <v-btn
                title="Next Button"
                color="primary"
                @click="$bubble('nextStep')"
                :disabled="$root.$data.installType === null"
                >Next <v-icon dark right>mdi-arrow-right</v-icon></v-btn
            >
            <v-btn title="Back Button" text @click="$bubble('prevStep')"
                >Back</v-btn
            >
        </div>
    </v-container>
</template>

<style>
.theme--light.v-sheet--outlined {
    border-width: 2px;
}

.theme--light.v-sheet--outlined.v-card--selected {
    border: 2px solid rgba(0, 0, 0, 0.77) !important;
}
</style>

<script>
export default {
    name: "InstallTypeStep",

    props: ["user", "device", "blobStore", "active"],

    data: () => ({
        firstSet: true,
    }),

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_installtype");
            }
        },
    },

    methods: {
        setType(newType) {
            this.$root.$data.installType = newType;

            if (this.firstSet) {
                this.firstSet = false;
                this.$bubble("nextStep");
            }

            this.saEvent(`install_type__${newType}`);
        },
    },
};
</script>
