export default {
    APP_VERSION: process.env.VUE_APP_APP_VERSION,
    OS_NAME: process.env.VUE_APP_OS_NAME,
    ACCENT_COLOR: "#63A528",
    SUPPORTED_DEVICES: [
        {
            name: "Pixel 4",
            model: "flame",
        },
        {
            name: "Pixel 4 XL",
            model: "coral",
        },
        {
            name: "Pixel 4a",
            model: "sunfish",
        },
        {
            name: "Pixel 4a 5G",
            model: "bramble",
        },
        {
            name: "Pixel 5",
            model: "redfin",
        },
        {
            name: "Pixel 5a",
            model: "barbet",
        },
        {
            name: "Pixel 6",
            model: "oriole",
        },
        {
            name: "Pixel 6a",
            model: "bluejay",
        },
        {
            name: "Pixel 7",
            model: "panther",
        },
    ],
    RELEASE_VARIANTS: {
        copperheados: {
            description: "CopperheadOS, Secure Android",
            suffix: "",
        },
    },
};
