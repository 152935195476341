<template>
    <v-card>
        <v-app-bar dark src="/homepage3.webp" width="100%">
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-btn
                icon
                title="Copperhead.co Home"
                right
                link
                href="https://copperhead.co/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <v-img
                    src="/copperheadlogo-min-white.svg"
                    max-height="28px"
                    contain
                />
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer
            app
            dark
            color="primary"
            v-model="drawer"
            absolute
            bottom
            temporary
            height="56rem"
            width="20rem"
        >
            <v-list nav>
                <v-list-item-group v-model="group">
                    <v-list-item
                        link
                        href="https://partners.copperhead.co/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Partner Home</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        href="https://copperhead.co/android/docs/updates/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <v-list-item-icon
                            ><v-icon>mdi-delta</v-icon></v-list-item-icon
                        >
                        <v-list-item-title
                            >CopperheadOS Change log</v-list-item-title
                        >
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group>
                    <v-list-item disabled dense>
                        <v-list-item-content>
                            <v-list-item-title>Welcome</v-list-item-title>
                            <v-list-item-subtitle> </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                        <v-list-item-content>
                            <amplify-sign-out slot="sign-out"
                                ><v-icon>mdi-logout</v-icon>
                            </amplify-sign-out>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
    name: "AppBar",
    props: ["user"],
    methods: {
        async logout() {
            try {
                await Auth.signOut();
            } catch (error) {
                alert(error.message);
            }
        },
    },
    data: () => ({
        drawer: false,
        group: null,
    }),
    watch: {
        group() {
            this.drawer = false;
        },
    },
};
</script>
