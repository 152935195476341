import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Config from "../config";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg",
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: Config.ACCENT_COLOR,
            },
        },
    },
});
