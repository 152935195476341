<template>
    <v-container
        class="d-flex justify-space-between flex-column flex-shrink-1 px-10"
    >
        <div class="mt-n4">
            <h6 class="text-h6 pb-4">Installation complete</h6>

            <div class="text-body-1" v-if="$root.$data.release !== null">
                <p>
                    Congratulations! Your
                    {{ getDeviceName($root.$data.product) }} is now running
                    {{ $root.$data.OS_NAME }} version
                    {{ $root.$data.release.version }}, Build ID
                    {{ $root.$data.release.buildId }}.
                </p>
                <p>
                    Thank you for helping {{ $root.$data.OS_NAME }} secure more
                    devices.
                </p>
            </div>
        </div>
        <div class="text-body-1">
            <p>
                <strong>
                    As of version 12.0.0 it is no longer necessary to manually
                    disable OEM Unlocking in Developer Options.
                </strong>
            </p>
            <p>
                The OEM Unlocking is now handled automatically after first boot.
            </p>
            <p>
                Your
                <strong>
                    {{ $root.$data.product }} is ready to use or ship!</strong
                >
                You may now select "Start" from the Bootloader menu to begin
                using the device, or select "Power off" to prepare the device
                for shipping. Make your selection using the Volume keys and the
                Power key to confirm.
            </p>
        </div>

        <v-checkbox
            label="I understand this device is ready to use or ship, and I am ready to flash another device."
            :value="0"
            outlined
            dark
            ripple
            :color="'green darken-2'"
            :class="'v-card--selected'"
            @click="confirmFinalSetup(true)"
        ></v-checkbox>

        <div class="d-flex justify-space-between">
            <v-btn
                title="Flash another device"
                text
                color="primary"
                @click="flashNew"
                :disabled="this.confirmFinalBool === false"
                >Flash next device...</v-btn
            >
        </div>
    </v-container>
</template>

<style>
.theme--light.v-sheet--outlined {
    border-width: 2px;
}

.theme--light.v-sheet--outlined.v-card--p-highlight {
    border: 2px solid #007cfa !important;
}
</style>

<script>
import { getDeviceName } from "../core/devices";

export default {
    name: "FinishStep",

    props: ["user", "device", "blobStore", "active"],

    data: () => ({
        confirmFinalBool: false,
    }),

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_finish");
            }
        },
    },

    methods: {
        confirmFinalSetup() {
            this.confirmFinalBool = true;
        },

        getDeviceName,

        flashNew() {
            window.location.reload();
        },
    },
};
</script>
